import * as React from 'react';

import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Content,
  StyledTitle,
  Description,
  IllustrationWrapper,
} from './acquisition-brand-protection.styles';
import { Illustration } from './illustration';

export const BrandProtection = ({ title, description }) => (
  <Wrapper>
    <Inner>
      <Content>
        <StyledTitle as="h2" size="xl">
          {title}
        </StyledTitle>
        <Description dangerouslySetInnerHTML={{ __html: getTextWithoutParagraph(description) }} />
      </Content>

      <IllustrationWrapper>
        <Illustration />
      </IllustrationWrapper>
    </Inner>
  </Wrapper>
);
