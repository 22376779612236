import * as React from 'react';

import { Container } from 'components/shared/container';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  StyledTitle,
  SectionsWrapper,
  Section,
  SectionContent,
  SectionTitle,
  SectionItemsWrapper,
  SectionItem,
  SectionItemIconWrapper,
  SectionItemContent,
  SectionItemTitle,
  SectionItemDescription,
  SectionItemIllustrationWrapper,
} from './acquisition-use-cases.styles';
import { DottedLine } from './dotted-line';
import Icon1 from './icons/acquisition-use-cases-icon1.svg';
import Icon2 from './icons/acquisition-use-cases-icon2.svg';
import Icon3 from './icons/acquisition-use-cases-icon3.svg';
import Icon4 from './icons/acquisition-use-cases-icon4.svg';
import Icon5 from './icons/acquisition-use-cases-icon5.svg';
import Illustration1 from './images/acquisition-use-cases-illustration1.svg';
import Illustration2 from './images/acquisition-use-cases-illustration2.svg';
import Illustration3 from './images/acquisition-use-cases-illustration3.svg';

export const UseCases = (props) => {
  const {
    title,
    block1Title,
    block1Feature1Title,
    block1Feature1Description,
    block2Title,
    block2Feature1Title,
    block2Feature1Description,
    block2Feature2Title,
    block2Feature2Description,
    block3Title,
    block3Feature1Title,
    block3Feature1Description,
    block3Feature2Title,
    block3Feature2Description,
  } = props;

  return (
    <Wrapper>
      <Container>
        <StyledTitle as="h2" size="lg">
          {title}
        </StyledTitle>

        <SectionsWrapper>
          <Section>
            <SectionContent>
              <SectionTitle
                as="h3"
                size="lg"
                dangerouslySetInnerHTML={{ __html: getTextWithoutParagraph(block1Title) }}
              />
              <SectionItemsWrapper>
                <SectionItem>
                  <SectionItemIconWrapper>
                    <Icon1 />
                  </SectionItemIconWrapper>

                  <SectionItemContent>
                    <SectionItemTitle as="h3" size="xs">
                      {block1Feature1Title}
                    </SectionItemTitle>
                    <SectionItemDescription>{block1Feature1Description}</SectionItemDescription>
                  </SectionItemContent>
                </SectionItem>
              </SectionItemsWrapper>
            </SectionContent>

            <SectionItemIllustrationWrapper>
              <Illustration1 />
            </SectionItemIllustrationWrapper>
          </Section>

          <Section>
            <SectionContent>
              <SectionTitle
                as="h3"
                size="lg"
                dangerouslySetInnerHTML={{ __html: getTextWithoutParagraph(block2Title) }}
              />
              <SectionItemsWrapper>
                <SectionItem>
                  <SectionItemIconWrapper>
                    <Icon2 />
                  </SectionItemIconWrapper>

                  <SectionItemContent>
                    <SectionItemTitle as="h3" size="xs">
                      {block2Feature1Title}
                    </SectionItemTitle>
                    <SectionItemDescription>{block2Feature1Description}</SectionItemDescription>
                  </SectionItemContent>
                </SectionItem>

                <SectionItem>
                  <SectionItemIconWrapper>
                    <Icon3 />
                  </SectionItemIconWrapper>

                  <SectionItemContent>
                    <SectionItemTitle as="h3" size="xs">
                      {block2Feature2Title}
                    </SectionItemTitle>
                    <SectionItemDescription>{block2Feature2Description}</SectionItemDescription>
                  </SectionItemContent>
                </SectionItem>
              </SectionItemsWrapper>
            </SectionContent>

            <SectionItemIllustrationWrapper>
              <Illustration2 />
            </SectionItemIllustrationWrapper>
          </Section>

          <Section>
            <SectionContent>
              <SectionTitle
                as="h3"
                size="lg"
                dangerouslySetInnerHTML={{ __html: getTextWithoutParagraph(block3Title) }}
              />
              <SectionItemsWrapper>
                <SectionItem>
                  <SectionItemIconWrapper>
                    <Icon4 />
                  </SectionItemIconWrapper>

                  <SectionItemContent>
                    <SectionItemTitle as="h3" size="xs">
                      {block3Feature1Title}
                    </SectionItemTitle>
                    <SectionItemDescription>{block3Feature1Description}</SectionItemDescription>
                  </SectionItemContent>
                </SectionItem>

                <SectionItem>
                  <SectionItemIconWrapper>
                    <Icon5 />
                  </SectionItemIconWrapper>

                  <SectionItemContent>
                    <SectionItemTitle as="h3" size="xs">
                      {block3Feature2Title}
                    </SectionItemTitle>
                    <SectionItemDescription>{block3Feature2Description}</SectionItemDescription>
                  </SectionItemContent>
                </SectionItem>
              </SectionItemsWrapper>
            </SectionContent>

            <SectionItemIllustrationWrapper>
              <Illustration3 />
            </SectionItemIllustrationWrapper>
          </Section>

          <DottedLine />
        </SectionsWrapper>
      </Container>
    </Wrapper>
  );
};
