import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import * as React from 'react';

import { RevealTextFromTop, FadeInBottom, FadeInBottomS } from 'animations';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  Content,
  Label,
  StyledLabelIcon,
  LabelText,
  StyledTitle,
  Description,
  StyledButton,
  BottomText,
  StyledIllustration,
  BackgroundIllustrationWrapper,
  StyledBackgroundIllustration,
} from './acquisition-intro.styles';
import { LogosBlock } from './logos-block';

const containerAnimationVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const Intro = (props) => {
  const { labelText, title, description, buttonText, bottomText, logos } = props;

  return (
    <Wrapper>
      <Inner>
        <Content>
          <motion.div initial="hidden" animate="visible" variants={containerAnimationVariants}>
            <motion.div variants={FadeInBottomS}>
              <Label>
                <StyledLabelIcon />
                <LabelText>{labelText}</LabelText>
              </Label>
            </motion.div>
            <StyledTitle size="xxl">
              {getTextWithoutParagraph(title)
                .split('<br />')
                .map((part, index) => (
                  <motion.div
                    key={`intro-title-${index}`}
                    variants={RevealTextFromTop}
                    dangerouslySetInnerHTML={{ __html: part }}
                  />
                ))}
            </StyledTitle>
            <motion.div variants={FadeInBottom}>
              <Description>{description}</Description>
            </motion.div>
            <motion.div variants={FadeInBottom}>
              <StyledButton component={Link} theme="light" to="/demo/">
                {buttonText}
              </StyledButton>
            </motion.div>
          </motion.div>
          <BottomText>{bottomText}</BottomText>
          <LogosBlock items={logos} />
        </Content>

        <StyledIllustration />
      </Inner>

      <BackgroundIllustrationWrapper>
        <StyledBackgroundIllustration />
      </BackgroundIllustrationWrapper>
    </Wrapper>
  );
};
