import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const LogoWrapper = styled.div`
  margin-right: 45px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.md} {
    display: none;
  }
`;
