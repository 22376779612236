import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const Wrapper = styled.img`
  position: absolute;
  top: 0;
  left: -80px;

  ${media.lg} {
    left: -120px;
    width: 600px;
  }

  ${media.md} {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
  }
`;
