import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';

import Stripes from './images/acquisition-testimonials-stripes.svg';

export const StyledStripes = styled(Stripes)`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  min-width: 700px;
  transform: translateX(-50%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  margin-bottom: 75px;

  ${media.md} {
    display: block;
  }

  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Inner = styled(Container)`
  border-top: 1px solid #d9dfe4;
  padding-top: 40px;

  ${media.md} {
    display: block;
    border-top: 0;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  background-color: #fbfbfe;
  padding-bottom: 190px;
  overflow: hidden;

  @media only screen and (min-width: 1920.98px) {
    padding-bottom: 80px;
  }

  ${media.sm} {
    padding-bottom: 80px;
  }
`;
