import * as React from 'react';

import { Wrapper, Inner, ItemsWrapper, StyledStripes } from './acquisition-testimonials.styles';
import { Item } from './item';

export const Testimonials = ({ items }) => (
  <Wrapper>
    <Inner>
      <ItemsWrapper>
        {items.map(({ text, author, logo }) => (
          <Item text={text} author={author} logo={logo} key={text} />
        ))}
      </ItemsWrapper>
    </Inner>

    <StyledStripes />
  </Wrapper>
);
