import * as React from 'react';

import { Wrapper, Dot } from './acquisition-use-cases-dotted-line.styles';

export const DottedLine = ({ className }) => (
  <Wrapper className={className}>
    {
      // eslint-disable-next-line react/no-array-index-key
      new Array(200).fill('').map((value, index) => (
        <Dot key={index} />
      ))
    }
  </Wrapper>
);
