import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Automate } from 'components/blocks/automate';
import { Brands } from 'components/blocks/brands';
import { Footer } from 'components/blocks/footer';
import { GetStarted } from 'components/blocks/get-started';
import { Header } from 'components/blocks/header';
import { SolutionsCTA } from 'components/blocks/solutions-cta';
import { BrandProtection } from 'components/pages/acquisition/brand-protection_old';
import { Difference } from 'components/pages/acquisition/difference_old';
import { Intro } from 'components/pages/acquisition/intro_old';
import { Testimonials } from 'components/pages/acquisition/testimonials_old';
import { UseCases } from 'components/pages/acquisition/use-cases_old';

import { buildPageMetadata } from '../utils/data-builders';

const Acquisition = ({
  data: {
    wpPage,
    wpPage: { acf },
  },
}) => {
  const {
    showHeader,
    showFooter,

    section1TextAboveTitle,
    section1Title,
    section1Description,
    section1ButtonText,
    section1BottomText,
    section1Logos,

    section2Title,
    section2Block1Title,
    section2Block1Feature1Title,
    section2Block1Feature1Description,
    section2Block2Title,
    section2Block2Feature1Title,
    section2Block2Feature1Description,
    section2Block2Feature2Title,
    section2Block2Feature2Description,
    section2Block3Title,
    section2Block3Feature1Title,
    section2Block3Feature1Description,
    section2Block3Feature2Title,
    section2Block3Feature2Description,

    section3Testimonial1Logo,
    section3Testimonial1Text,
    section3Testimonial1Author,
    section3Testimonial1AuthorRole,
    section3Testimonial2Logo,
    section3Testimonial2Text,
    section3Testimonial2Author,
    section3Testimonial2AuthorRole,

    section4Title,
    section4Block1Title,
    section4Block1Content,
    section4Block2Title,
    section4Block2Content,
    section4ButtonText,

    brandsTitle,
    brandsDescription,

    section6Title,
    section6Description,

    solutionsCtaTitle,
    solutionsCtaButtonText,
    solutionsCtaButtonUrl,
    solutionsCtaLogos,
    solutionsCtaTestimonialAuthorPhoto,
    solutionsCtaTestimonialText,
    solutionsCtaTestimonialAuthorRole,
    solutionsCtaTestimonialAuthorCompany,
    solutionsCtaTestimonialAuthorName,
  } = acf;

  const testimonialsItems = [
    {
      logo: section3Testimonial1Logo,
      text: section3Testimonial1Text,
      author: {
        name: section3Testimonial1Author,
        role: section3Testimonial1AuthorRole,
      },
    },
    {
      logo: section3Testimonial2Logo,
      text: section3Testimonial2Text,
      author: {
        name: section3Testimonial2Author,
        role: section3Testimonial2AuthorRole,
      },
    },
  ];

  const differenceSections = [
    {
      title: section4Block1Title,
      items: section4Block1Content.trim().split('\n'),
      isFeatured: false,
    },
    {
      title: section4Block2Title,
      items: section4Block2Content.trim().split('\n'),
      isFeatured: true,
    },
  ];

  const solutionsCTATestimonial = {
    text: solutionsCtaTestimonialText,
    author: {
      photo: solutionsCtaTestimonialAuthorPhoto,
      role: solutionsCtaTestimonialAuthorRole,
      company: solutionsCtaTestimonialAuthorCompany,
      name: solutionsCtaTestimonialAuthorName,
    },
  };

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header withoutActions={showHeader === 'false'} />
      <Intro
        labelText={section1TextAboveTitle}
        title={section1Title}
        description={section1Description}
        buttonText={section1ButtonText}
        bottomText={section1BottomText}
        logos={section1Logos}
      />
      <UseCases
        title={section2Title}
        block1Title={section2Block1Title}
        block1Feature1Title={section2Block1Feature1Title}
        block1Feature1Description={section2Block1Feature1Description}
        block2Title={section2Block2Title}
        block2Feature1Title={section2Block2Feature1Title}
        block2Feature1Description={section2Block2Feature1Description}
        block2Feature2Title={section2Block2Feature2Title}
        block2Feature2Description={section2Block2Feature2Description}
        block3Title={section2Block3Title}
        block3Feature1Title={section2Block3Feature1Title}
        block3Feature1Description={section2Block3Feature1Description}
        block3Feature2Title={section2Block3Feature2Title}
        block3Feature2Description={section2Block3Feature2Description}
      />
      <Testimonials items={testimonialsItems} />
      <Difference
        title={section4Title}
        sections={differenceSections}
        buttonText={section4ButtonText}
      />
      <Brands title={brandsTitle} description={brandsDescription} type="acquisition" />
      <BrandProtection title={section6Title} description={section6Description} />
      <Automate />
      <SolutionsCTA
        title={solutionsCtaTitle}
        buttonText={solutionsCtaButtonText}
        buttonUrl={solutionsCtaButtonUrl.uri}
        logos={solutionsCtaLogos}
        testimonial={solutionsCTATestimonial}
      />
      <GetStarted />
      {showFooter !== 'false' && <Footer />}
    </Layout>
  );
};

export default Acquisition;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        showHeader
        showFooter

        section1TextAboveTitle
        section1Title
        section1Description
        section1ButtonText
        section1BottomText
        section1Logos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        section2Title
        section2Block1Title
        section2Block1Feature1Title
        section2Block1Feature1Description
        section2Block2Title
        section2Block2Feature1Title
        section2Block2Feature1Description
        section2Block2Feature2Title
        section2Block2Feature2Description
        section2Block3Title
        section2Block3Feature1Title
        section2Block3Feature1Description
        section2Block3Feature2Title
        section2Block3Feature2Description

        section3Testimonial1Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section3Testimonial1Text
        section3Testimonial1Author
        section3Testimonial1AuthorRole
        section3Testimonial2Logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section3Testimonial2Text
        section3Testimonial2Author
        section3Testimonial2AuthorRole

        section4Title
        section4Block1Title
        section4Block1Content
        section4Block2Title
        section4Block2Content
        section4ButtonText

        brandsTitle
        brandsDescription

        section6Title
        section6Description

        solutionsCtaTitle
        solutionsCtaButtonText
        solutionsCtaButtonUrl {
          ... on WpPage {
            uri
          }
        }
        solutionsCtaLogos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        solutionsCtaTestimonialAuthorPhoto {
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        solutionsCtaTestimonialText
        solutionsCtaTestimonialAuthorRole
        solutionsCtaTestimonialAuthorCompany
        solutionsCtaTestimonialAuthorName
      }
    }
  }
`;
