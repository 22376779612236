import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const Dot = styled.div`
  flex-shrink: 0;
  width: 2px;
  height: 2px;
  background-color: #d9dfe4;
  border-radius: 50%;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  display: flex;
  flex-direction: column;
  height: 1225px;
  transform: translateX(-50%);
  overflow: hidden;

  ${media.lg} {
    display: none;
  }
`;
