import styled, { css } from 'styled-components';

import Logo from 'assets/images/logo.svg';
import { fontSize, color, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

import Stripes from './images/acquisition-difference-stripes.svg';

export const StyledStripes = styled(Stripes)`
  position: absolute;
  bottom: -70px;
  left: 50%;
  z-index: 1;
  width: 100%;
  min-width: 850px;
  transform: translateX(-50%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }
`;

export const SectionItemButtonWrapper = styled.div`
  text-align: center;
`;

export const SectionItemDescription = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 0;

  ${media.sm} {
    line-height: 1.25;
  }

  strong {
    font-weight: 500;
  }
`;

export const SectionItemIcon = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #eaeef2;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 25px;

  ${media.sm} {
    margin-top: 0;
    margin-right: 15px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 2px;
    background-color: #a7aec1;
    transform: translate(-50%, -50%);
  }
`;

export const SectionItem = styled.li`
  display: flex;
  margin-bottom: 15px;
`;

export const SectionItemsWrapper = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

export const SectionContent = styled.div`
  padding-top: 35px;
  padding-right: 40px;
  padding-bottom: 60px;
  padding-left: 40px;

  ${media.xs} {
    padding-right: 20px;
    padding-bottom: 70px;
    padding-left: 20px;
  }
`;

export const SectionHeader = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: #6b7696;
  border-bottom: 1px solid #eaeef2;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Section = styled.div`
  flex-basis: 450px;
  max-width: 450px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);

  ${media.md} {
    margin-right: auto;
    margin-left: auto;
  }

  ${(p) => css`
    ${p.featured &&
    css`
      margin-top: -20px;
      margin-left: -30px;
      margin-bottom: -20px;

      ${media.md} {
        margin-top: 0;
        margin-bottom: 0;
      }

      ${SectionHeader} {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      ${SectionContent} {
        ${media.sm} {
          padding-bottom: 40px;
        }
      }

      ${SectionItemsWrapper} {
        margin-bottom: 50px;

        ${media.sm} {
          margin-bottom: 75px;
        }
      }

      ${SectionItemIcon} {
        background-color: #d5f9ff;

        &:before {
          background-color: #38cfe7;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 8px;
          background-color: #38cfe7;
          transform: translate(-50%, -50%);
        }
      }
    `}
  `}
`;

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${media.md} {
    display: block;
  }
`;

export const StyledTitle = styled(Title)`
  font-weight: 400;
  text-align: center;
  margin-bottom: 70px;

  ${media.sm} {
    margin-bottom: 15px;
  }
`;

export const StyledLogo = styled(Logo)`
  width: 40px;
  fill: ${color.primary};
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background-color: #e2fbff;
  border-radius: 50%;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
`;

export const Wrapper = styled.section`
  position: relative;
  background-image: linear-gradient(347deg, #b7b7f0, #f5f5ff 34%, #ffffff);
  padding-top: 20px;
  padding-bottom: 220px;

  @media only screen and (min-width: 1920.98px) {
    padding-bottom: 100px;
  }

  ${media.sm} {
    padding-top: 35px;
    padding-bottom: 60px;
  }
`;
