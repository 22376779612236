import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const IllustrationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  ${media.md} {
    position: relative;
    left: 0;
    text-align: center;
    transform: none;
  }
`;

export const Description = styled.p`
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0;

  ${media.md} {
    margin-bottom: 50px;
  }

  ${media.sm} {
    margin-bottom: 40px;
  }

  strong {
    font-weight: 700;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 30px;

  ${media.md} {
    max-width: 450px;
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  max-width: 450px;
  padding-top: 60px;
  margin-left: auto;

  ${media.lg} {
    padding-top: 0;
  }

  ${media.md} {
    max-width: 100%;
    margin-left: 0;
  }

  ${media.sm} {
    max-width: 100%;
  }
`;

export const Inner = styled(Container)`
  position: relative;
`;

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 175px;

  ${media.sm} {
    padding-bottom: 100px;
  }
`;
