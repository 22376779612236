import { Link } from 'gatsby';
import * as React from 'react';

import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  LogoWrapper,
  StyledLogo,
  StyledTitle,
  SectionsWrapper,
  Section,
  SectionHeader,
  SectionContent,
  SectionItemsWrapper,
  SectionItem,
  SectionItemIcon,
  SectionItemDescription,
  SectionItemButtonWrapper,
  StyledStripes,
} from './acquisition-difference.styles';

export const Difference = (props) => {
  const { title, sections, buttonText } = props;

  return (
    <Wrapper>
      <Container>
        <LogoWrapper>
          <StyledLogo />
        </LogoWrapper>

        <StyledTitle as="h2" size="lg">
          {title}
        </StyledTitle>

        <SectionsWrapper>
          {sections.map(({ title, items, isFeatured }) => (
            <Section featured={isFeatured} key={title}>
              <SectionHeader>{title}</SectionHeader>
              <SectionContent>
                <SectionItemsWrapper>
                  {items.map((item) => (
                    <SectionItem key={item}>
                      <SectionItemIcon />
                      <SectionItemDescription
                        dangerouslySetInnerHTML={{
                          __html: getTextWithoutParagraph(item),
                        }}
                      />
                    </SectionItem>
                  ))}
                </SectionItemsWrapper>

                {isFeatured && (
                  <SectionItemButtonWrapper>
                    <Button theme="light" component={Link} to="/demo/">
                      {buttonText}
                    </Button>
                  </SectionItemButtonWrapper>
                )}
              </SectionContent>
            </Section>
          ))}
        </SectionsWrapper>
      </Container>

      <StyledStripes />
    </Wrapper>
  );
};
