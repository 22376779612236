import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { IllustrationView } from './acquisition-brand-protection-illustration.view';

export const Illustration = () => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: {
          eq: "pages/acquisition/brand-protection/acquisition-brand-protection-illustration.svg"
        }
      ) {
        publicURL
      }
    }
  `);

  return <IllustrationView src={illustration.publicURL} />;
};
