import styled from 'styled-components';

import { fontSize, media } from 'components/_settings/variables';
import { Title } from 'components/shared/title';

export const SectionItemIllustrationWrapper = styled.div`
  position: absolute;

  ${media.lg} {
    position: static;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const SectionItemIconWrapper = styled.div`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 20px;

  ${media.xs} {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
`;

export const SectionItemDescription = styled.p`
  line-height: 1.6;
  margin-top: 0;
  margin-bottom: 0;

  ${media.sm} {
    font-size: ${fontSize.sm};
  }
`;

export const SectionItemTitle = styled(Title)`
  color: #806de2;
  margin-bottom: 5px;
`;

export const SectionItemContent = styled.div``;

export const SectionItem = styled.li`
  display: flex;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionItemsWrapper = styled.ul`
  max-width: 500px;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  ${media.lg} {
    max-width: initial;
  }
`;

export const SectionTitle = styled(Title)`
  margin-bottom: 65px;

  ${media.lg} {
    margin-bottom: 30px;
  }

  strong {
    font-weight: inherit;
    color: #7e77c9;
  }
`;

export const SectionContent = styled.div`
  ${media.lg} {
    margin-bottom: 50px;
  }
`;

export const Section = styled.div`
  position: relative;
  display: flex;

  ${media.lg} {
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-weight: 700;
    color: #806de2;
    background-color: #e6e5ff;
    border-radius: 50%;
    transform: translateX(-50%);

    ${media.lg} {
      display: none;
    }
  }

  &:nth-of-type(1) {
    margin-bottom: 310px;

    ${media.lg} {
      margin-bottom: 70px;
    }

    &:after {
      content: '1';
    }

    ${SectionItemIllustrationWrapper} {
      top: -20px;
      right: 35px;
      width: 470px;

      ${media.md} {
        max-width: 300px;
        width: 70%;
      }
    }
  }

  &:nth-of-type(2) {
    margin-bottom: 250px;

    ${media.lg} {
      margin-bottom: 70px;
    }

    &:after {
      content: '2';
    }

    ${SectionContent} {
      margin-left: auto;
    }

    ${SectionTitle} {
      margin-right: -20px;

      ${media.lg} {
        margin-right: 0;
      }
    }

    ${SectionItemIllustrationWrapper} {
      top: -20px;
      left: 60px;
      width: 344px;

      ${media.md} {
        max-width: 300px;
        width: 70%;
      }
    }
  }

  &:nth-of-type(3) {
    &:after {
      content: '3';
    }

    ${SectionItemIllustrationWrapper} {
      top: 10px;
      right: 80px;
      width: 330px;

      ${media.md} {
        max-width: 300px;
        width: 70%;
      }
    }
  }
`;

export const SectionsWrapper = styled.div`
  position: relative;
`;

export const StyledTitle = styled(Title)`
  font-weight: 400;
  text-align: center;
  margin-bottom: 140px;

  ${media.lg} {
    text-align: left;
    margin-bottom: 30px;
  }

  ${media.sm} {
    font-size: ${fontSize.md};
    margin-bottom: 20px;
  }
`;

export const Wrapper = styled.section`
  background-color: #fbfbfe;
  padding-top: 110px;
  padding-bottom: 70px;

  ${media.sm} {
    padding-top: 40px;
    padding-bottom: 60px;
  }
`;
