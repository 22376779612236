import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';
import { rgba } from 'utils';

import LabelIcon from './icons/acquisition-intro-label-icon.svg';
import BackgroundIllustration from './images/acquisition-intro-background-illustration.svg';
import Illustration from './images/acquisition-intro-illustration.svg';

export const StyledBackgroundIllustration = styled(BackgroundIllustration)`
  position: absolute;
  top: -775px;
  right: -600px;
  height: 2280px;

  ${media.lg} {
    right: -700px;
  }

  ${media.md} {
    top: auto;
    right: -250px;
    bottom: -375px;
    height: 1100px;
  }

  ${media.sm} {
    bottom: -450px;
  }

  ${media.xs} {
    right: -190px;
    bottom: -400px;
    height: 875px;
  }
`;

export const BackgroundIllustrationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }

  ${media.lg} {
    max-width: 992px;
  }

  ${media.md} {
    max-width: 768px;
  }

  ${media.sm} {
    max-width: 576px;
  }
`;

export const StyledIllustration = styled(Illustration)`
  position: absolute;
  top: -50px;
  right: 100px;
  width: 435px;

  ${media.lg} {
    top: 0;
  }

  ${media.md} {
    top: auto;
    bottom: -225px;
    width: 300px;
  }

  ${media.sm} {
    bottom: -350px;
  }

  ${media.xs} {
    width: 280px;
    right: 20px;
  }
`;

export const BottomText = styled.p`
  font-size: ${fontSize.lg};
  line-height: 1.83;
  text-align: center;
  color: ${rgba(color.text.primary, 0.5)};
  margin-top: 0;
  margin-bottom: 40px;

  ${media.md} {
    display: none;
  }
`;

export const StyledButton = styled(Button)`
  margin-bottom: 120px;

  ${media.md} {
    margin-bottom: 0;
  }
`;

export const Description = styled.p`
  max-width: 580px;
  line-height: 1.5;
  color: ${color.text.secondary};
  margin-top: 0;
  margin-bottom: 40px;

  ${media.lg} {
    max-width: 450px;
  }

  ${media.sm} {
    margin-bottom: 25px;
  }
`;

export const StyledTitle = styled(Title)`
  max-width: 550px;
  margin-bottom: 35px;

  ${media.lg} {
    max-width: 350px;
  }

  ${media.md} {
    max-width: 550px;
  }

  ${media.sm} {
    margin-bottom: 25px;
  }

  strong {
    font-weight: inherit;
    color: #787ed3;
  }
`;

export const LabelText = styled.span`
  font-size: ${fontSize.sm};
  font-weight: 500;
  line-height: normal;
  color: #787fd3;
`;

export const StyledLabelIcon = styled(LabelIcon)`
  flex-shrink: 0;
  margin-right: 20px;

  ${media.xs} {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  ${media.xs} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  ${media.sm} {
    margin-bottom: 30px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const Wrapper = styled.section`
  position: relative;
  background-image: linear-gradient(199deg, #fbf9ff, #ffffff);
  padding-top: 190px;
  padding-bottom: 85px;
  overflow: hidden;

  ${media.md} {
    padding-bottom: 250px;
  }

  ${media.sm} {
    padding-top: 90px;
    padding-bottom: 350px;
  }
`;
